<script setup>
import { useRuntimeConfig } from '#imports';
import { onMounted, ref, computed } from 'vue';
import { useAuth } from '@/composables/use-auth';
import { usePersonalDetails } from '@/composables/use-personal-details';
import { useRouter } from 'vue-router';
import { useNotifsTailed } from '@/composables/use-notifs';
import { useToast } from '@/composables/use-ui';
import GlobalToast from '@/components/commons/GlobalToast.vue';

const router = useRouter();
const { showToast } = useToast();

useNotifsTailed({
  onNewNotif: (notif) => {
    showToast(notif.title, { type: 'alert-info' });
  },
});
const { currentUser, signout } = useAuth();
const { getItem: getPersonalDetails } = usePersonalDetails();

const personalDetails = ref({});
const email = computed(() => currentUser.value?.email);
const picURL = computed(() => personalDetails.value?.picURL);

onMounted(async () => {
  if (!currentUser.value) {
    signout();
    router.push({ name: 'login' });
  }
  
  personalDetails.value = await getPersonalDetails(currentUser.value?.uid);
});

async function onSignOut () {
  await signout();
  router.push({ name: 'login' });
}

const appVersion = useRuntimeConfig().public?.appVersion;

const drawer = ref(false);
</script>

<template>
  <div data-theme="parmazip">
    <div class="navbar justify-between bg-base-100 border-b">
      <div>
        <label for="nav-drawer" class="btn btn-sm btn-primary btn-outline btn-square mr-2 drawer-button lg:hidden">
          <i class="las la-bars text-2xl"/>
        </label>
        <div class="flex items-center gap-2">
          <nuxt-link href="/" class="flex-1">
            <img src="@/assets/images/parmazip-logo-long.png" class="h-[60px]" alt="Parmazip Logo">
          </nuxt-link>
          <h1 class="text-2xl text-primary font-general mb-1">Patients</h1>
        </div>
      </div>
      <div class="flex-none">
        <div class="dropdown dropdown-end">
          <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar">
            <div class="w-10 rounded-full bg-slate-50">
              <img
                v-if="picURL"
                :alt="email"
                :src="picURL"
              >
              <i v-else class="las la-user text-2xl mt-1"></i>
            </div>
          </div>
          <ul
            tabindex="0"
            class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
            <li>
              <nuxt-link to="/dashboard/patient-profile">
                Profile
              </nuxt-link>
            </li>
            <li>
              <nuxt-link @click="onSignOut">
                Log Out
              </nuxt-link>
            </li>
            <span class="text-xs ml-3 mt-2">App Version: {{ appVersion }}</span>
          </ul>
        </div>
      </div>
    </div>
    <div class="drawer lg:drawer-open">
      <input v-model="drawer" id="nav-drawer" type="checkbox" class="drawer-toggle" >
      <div class="drawer-content flex flex-col items-center justify-center">
        <slot/>
      </div>
      <div class="drawer-side">
        <label for="nav-drawer" aria-label="close sidebar" class="drawer-overlay"/>
        <ul class="menu bg-slate-100 text-base-content min-h-full w-80 p-4">
          <!-- Sidebar content here -->
          <li>
            <nuxt-link to="/dashboard/patient" @click="drawer = false;">
              <i class="las la-home text-2xl"/>
              Dashboard
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/patient-profile" @click="drawer = false;">
              <i class="las la-user text-2xl"/>
              Profile
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/patient-appointments" @click="drawer = false;">
              <i class="las la-calendar text-2xl"/>
              My Appointments
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/patient-billing" @click="drawer = false;">
              <i class="las la-credit-card text-2xl"/>
              Billing
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/patient-records" @click="drawer = false;">
              <i class="las la-briefcase-medical text-2xl"/>
              Records
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/patient-notifs" @click="drawer = false;">
              <i class="las la-bell text-2xl"/>
              Notifications
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <GlobalToast/>
</template>
